/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../store/hooks/AppHooks'
import NftService from '../../services/nft.service'
import iconHome from '../../assets/images/portal/icon_bottom_nav/icon_home_white.png'
import iconFCRank from '../../assets/images/portal/icon_bottom_nav/FC_rank_G.png'
import iconHeart from '../../assets/images/portal/icon_bottom_nav/icon_heart_white.png'
import iconShop from '../../assets/images/portal/icon_bottom_nav/icon_shop_nft.png'
import iconUser from '../../assets/images/portal/icon_bottom_nav/icon_user_white.png'
import { checkArtistHiddenItems } from '../../utils/utils'

const BottomNav = (): JSX.Element => {
    const { pathname } = useLocation()
    const path = pathname.split('/')[2]
    const auth = useAppSelector((state) => state.user.isAuth)
    const user = useAppSelector((state) => state.user.me)
    const currentLink = window.location.href
    const profileLink = `${process.env.REACT_APP_EC_API_URL}/mypage`
    const artistInfo = useAppSelector((state) => state.artist.artistInfo)
    const isLoadingArtistInfo = useAppSelector(
        (state) => state.artist.isLoadingArtistInfo
    )

    const handleMallClick = () => {
        if (auth) {
            NftService.getRedirectUrl(user.pialaAuthToken, 'auctions').then(
                (url) => {
                    window.location.href = url
                }
            )
        } else {
            NftService.getRedirectUrl(null, 'auctions').then((url) => {
                window.location.href = url
            })
        }
    }

    const handleContentClick = () => {
        if (auth) {
            NftService.getRedirectUrl(user.pialaAuthToken, 'my-auction').then(
                (url) => {
                    window.location.href = url
                }
            )
        } else {
            window.location.href = `${profileLink}/login?_target_path=${currentLink}`
        }
    }

    const handleProfileClick = () => {
        if (auth) {
            NftService.getRedirectUrl(user.pialaAuthToken, 'mypage').then(
                (url) => {
                    window.location.href = url
                }
            )
        } else {
            NftService.getRedirectUrl(null, 'mypage').then((url) => {
                window.location.href = url
            })
        }
    }

    return (
        <div className="portal__bottom-navigator">
            <ul className="portal__bottom-navigator__wrapper">
                <li
                    className={`portal__bottom-navigator__item ${
                        path ? '' : 'active'
                    }`}
                >
                    <Link
                        to="/portal"
                        className="portal__bottom-navigator__item__link menu_icon_top"
                    >
                        <img
                            src={iconHome}
                            alt="Home"
                            className="portal__bottom-navigator__item__icon"
                        />
                        <p className="menu_icon_top_text">TOP</p>
                    </Link>
                </li>
                <li
                    className={`portal__bottom-navigator__item ${
                        path === 'mail' ? 'active' : ''
                    }`}
                >
                    <a
                        onClick={handleContentClick}
                        role="button"
                        tabIndex={0}
                        className="portal__bottom-navigator__item__link menu_icon_fcRank"
                        href="/portal/listFC"
                    >
                        <img
                            src={iconFCRank}
                            alt="Fc Rank"
                            className="portal__bottom-navigator__item__icon"
                        />
                        <p>クラブ</p>
                    </a>
                </li>
                <li
                    className={`portal__bottom-navigator__item ${
                        path === 'content' ? 'active' : ''
                    }`}
                >
                    <Link
                        to="/portal/favorites"
                        className="portal__bottom-navigator__item__link menu_icon_heart"
                    >
                        <img
                            src={iconHeart}
                            alt="favorite"
                            className="portal__bottom-navigator__item__icon img_icon_heart"
                        />
                        <p>お気に入り</p>
                    </Link>
                </li>
                <li
                    className={`portal__bottom-navigator__item ${
                        path ? '' : 'active'
                    }`}
                >
                    <a
                        onClick={handleMallClick}
                        role="button"
                        tabIndex={0}
                        className="portal__bottom-navigator__item__link menu_icon_nft"
                    >
                        <img
                            src={iconShop}
                            alt="Nft"
                            className={`portal__bottom-navigator__item__icon img_icon_nft ${
                                path === 'favorites' ? 'active' : ''
                            }`}
                        />
                        <p>NFT</p>
                    </a>
                </li>
                <li className="portal__bottom-navigator__item">
                    <a
                        onClick={handleProfileClick}
                        role="button"
                        tabIndex={0}
                        className="portal__bottom-navigator__item__link menu_icon_user"
                    >
                        <img
                            src={iconUser}
                            alt="Profile"
                            className="portal__bottom-navigator__item__icon"
                        />
                        <p>マイページ</p>
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default BottomNav
