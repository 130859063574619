import React from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import { useAppSelector } from '../../store/hooks/AppHooks'
import EcService from '../../services/ec.service'

type HeaderMenuOverlayPropsType = { handleVisible: () => void }

const HeaderMenuOverlay = ({
    handleVisible,
}: HeaderMenuOverlayPropsType): JSX.Element => {
    const auth = useAppSelector((state) => state.user.isAuth)
    const currentLink = window.location.href
    const profileLink = `${process.env.REACT_APP_EC_API_URL}/mypage${
        !auth ? `/login?_target_path=${currentLink}` : ''
    }`
    return (
        <Menu
            className="portal__header__side-menu-menu__overlay border-r-none"
            onClick={handleVisible}
        >
            {!auth && (
                <Menu.Item
                    className="portal__header__side-menu-menu__item"
                    key={Math.floor(300000 * Math.random())}
                >
                    <a href={profileLink}>ログイン</a>
                </Menu.Item>
            )}
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <Link to="/portal/creator/list">クリエイター一覧</Link>
            </Menu.Item>
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <a
                    href={`${process.env.REACT_APP_EC_API_URL}${process.env.REACT_APP_POINT_PRODUCTS}`}
                >
                    ポイント購入
                </a>
            </Menu.Item>
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <Link to="/portal/guide/user">使い方ガイド</Link>
            </Menu.Item>
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <a href="https://contents.cyberstar.jp/personal_portal">
                    CYBER STARとは
                </a>
            </Menu.Item>
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <Link to="/portal/faq">FAQ</Link>
            </Menu.Item>
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <Link to="/portal/contact">お問い合わせ</Link>
            </Menu.Item>
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <a href={`${EcService.baseURL}/entry/artist`}>
                    クリエイター申請
                </a>
            </Menu.Item>
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <Link to="/portal/page/tradelaw">特定商取引法に基づく表記</Link>
            </Menu.Item>
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <Link to="/portal/page/term-of-use">
                    サイバースター・クリエイター利用規約
                </Link>
            </Menu.Item>
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <Link to="/portal/page/membership-term-of-use">
                    サイバースター・ユーザー利用規約
                </Link>
            </Menu.Item>
            <Menu.Item
                className="portal__header__side-menu-menu__item"
                key={Math.floor(300000 * Math.random())}
            >
                <Link to="/portal/page/nft-terms-of-services">
                    NFTモール利用規約
                </Link>
            </Menu.Item>
            {auth ? (
                <Menu.Item key={Math.floor(300000 * Math.random())}>
                    <a
                        type="button"
                        href={`${process.env.REACT_APP_EC_API_URL}/prelogout`}
                    >
                        ログアウト
                    </a>
                </Menu.Item>
            ) : null}
        </Menu>
    )
}
export default HeaderMenuOverlay
