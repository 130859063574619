import React from 'react'
import { RouteChildrenProps } from 'react-router-dom'
import Header from '../components/portal/Header'
import PortalRoutes from '../routes/PortalRoutes'
import withMasterLayout from '../components/hoc/WithMasterLayout'

export const PortalContext = React.createContext({})

function Layout({ match }: RouteChildrenProps): JSX.Element {
    return (
        <PortalContext.Provider value={{}}>
            <section className="portal portal__wrapper">
                <div className="portal__container">
                    <Header />
                    <PortalRoutes prefix={match ? match.path : ''} />
                </div>
            </section>
        </PortalContext.Provider>
    )
}

const PortalLayout = withMasterLayout<RouteChildrenProps>(Layout)
export default PortalLayout
