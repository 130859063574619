import { MediaType } from './MediaType'

export type ArtistPublishing = {
    title: string
    link: string
}

export type ArtistInfoType = {
    name: string
    slug: string
    email: string
    biography: string
    introduction: string
    address: string
    link: string
    artistId: number
    picture: MediaType | null
    publishing: ArtistPublishing[]
    twitter: string
    facebook: string
    instagram: string
    tiktok: string
}

export const initial: ArtistInfoType = {
    name: 'artist name',
    slug: '',
    email: '',
    biography: '',
    introduction: '',
    address: '',
    link: '',
    artistId: 0,
    picture: null,
    publishing: [],
    twitter: '',
    facebook: '',
    instagram: '',
    tiktok: '',
}
