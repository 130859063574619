import { call, put, takeLatest, all } from 'redux-saga/effects'
import BlogService from '../../services/blog/index'
import { BlogActions, BlogActionsType } from '../constant/blog.const'
import { BlogType } from '../../type/BlogType'

export function* count(actions: BlogActions) {
    try {
        const blogs: number = yield call(
            BlogService.count.bind(BlogService),
            actions.payload
        )
        yield put({
            type: BlogActionsType.setCount,
            payload: blogs,
        } as BlogActions)
    } catch (e) {
        // TODO: handle error
        console.error(e)
    }
}

export function* getBlogs(actions: BlogActions) {
    try {
        yield put({
            type: BlogActionsType.setLoading,
            payload: true,
        } as BlogActions)
        const blogs: Array<BlogType> = yield call(
            BlogService.get.bind(BlogService),
            {
                ...actions.payload.queries,
                _sort: 'id:DESC',
            },
            actions.payload.artistId
        )
        yield put({
            type: BlogActionsType.setList,
            payload: blogs,
        } as BlogActions)
        yield put({
            type: BlogActionsType.setLoading,
            payload: false,
        } as BlogActions)
    } catch (e) {
        // TODO: handle error
        console.error(e)
    }
}

export function* blogSaga() {
    yield all([
        takeLatest(BlogActionsType.count, count),
        takeLatest(BlogActionsType.fetchList, getBlogs),
    ])
}
