import React from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../store/hooks/AppHooks'

function Footer(): JSX.Element {
    const artistInfo = useAppSelector((state) => state.artist.artistInfo)
    return (
        <footer className="portal__footer">
            {/* <div className="portal__footer__container portal__footer__container-row-large">
                <div className="portal__footer__item-bordered">
                    <Link to="/portal/page/usage">
                        <p>使い方</p>
                    </Link>
                </div>
                <div className="portal__footer__item-bordered">
                    <Link to="/portal/fa
                    q">
                        <p>Q &amp; A</p>
                    </Link>
                </div>
            </div> */}
            <div className="portal__footer__wrapper">
                <div className="portal__footer__container portal__footer__container-col">
                    <div className="portal__footer__container portal__footer__container-row-small">
                        <div className="portal__footer__item-underlined">
                            <Link to="/portal/contact">
                                <p>お問い合わせ</p>
                            </Link>
                        </div>
                        <div className="portal__footer__item-underlined">
                            <a
                                href="https://www.piala.co.jp/profile/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <p>運営会社</p>
                            </a>
                        </div>
                        <div className="portal__footer__item-underlined">
                            <a
                                href="https://www.piala.co.jp/privacy"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <p>プライバシーポリシー</p>
                            </a>
                        </div>
                        <div className="portal__footer__item-underlined">
                            <Link to="/portal/page/tradelaw">
                                特定商取引法に基づく表記
                            </Link>
                        </div>
                        <div className="portal__footer__item-underlined">
                            <Link to="/portal/page/term-of-use">
                                サイバースター・クリエイター利用規約
                            </Link>
                        </div>
                        <div className="portal__footer__item-underlined">
                            <Link to="/portal/page/membership-term-of-use">
                                サイバースター・ユーザー利用規約
                            </Link>
                        </div>
                        <div className="portal__footer__item-underlined">
                            <Link to="/portal/page/nft-terms-of-services">
                                NFTモール利用規約
                            </Link>
                        </div>
                    </div>

                    <div className="portal__footer__container portal__footer__container-row-small">
                        <div className="portal__footer__item-copy_right">
                            <p>COPYRIGHT &copy; CYBERSTAR/PIALA</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer
