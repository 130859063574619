import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducers'
import SagaMiddleware from './sagas'
import rootSaga from './sagas/root.saga'

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(SagaMiddleware))
)
SagaMiddleware.run(rootSaga)

export type rootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
