import {
    ProductActions,
    ProductActionTypes,
    ProductStateType,
} from '../constant/product.const'

const initialState: ProductStateType = {
    isLiveTicketsLoading: false,
    isArtistGoodsLoading: false,
    isPersonalTalkTicketsLoading: false,
    liveTickets: [],
    artistGoods: [],
    personalTalksTickets: [],
    redirectUrlToLiveTickets: '',
    redirectUrlToArtistGoods: '',
    redirectUrlToPersonalTalkTickets: '',
    redirectUrlToGachaCoin: '',
}

function ProductReducers(
    state = initialState,
    actions: ProductActions
): ProductStateType {
    switch (actions.type) {
        case ProductActionTypes.setLiveTicketsLoading:
            return { ...state, isLiveTicketsLoading: actions.payload }
        case ProductActionTypes.setArtistGoodsLoading:
            return { ...state, isArtistGoodsLoading: actions.payload }
        case ProductActionTypes.setPersonalTalksLoading:
            return { ...state, isPersonalTalkTicketsLoading: actions.payload }
        case ProductActionTypes.setLiveTickets:
            return { ...state, liveTickets: actions.payload }
        case ProductActionTypes.setArtistGoods:
            return { ...state, artistGoods: actions.payload }
        case ProductActionTypes.setPersonalTalkTickets:
            return { ...state, personalTalksTickets: actions.payload }
        case ProductActionTypes.setRedirectUrlToLiveTickets:
            return { ...state, redirectUrlToLiveTickets: actions.payload }
        case ProductActionTypes.setRedirectUrlToArtistGoods:
            return { ...state, redirectUrlToArtistGoods: actions.payload }
        case ProductActionTypes.setRedirectUrlToPersonalTalkTickets:
            return {
                ...state,
                redirectUrlToPersonalTalkTickets: actions.payload,
            }
        case ProductActionTypes.setRedirectUrlToGachaCoin:
            return { ...state, redirectUrlToGachaCoin: actions.payload }
        default:
            return state
    }
}

export default ProductReducers
