import { Action } from 'redux'
import { FaqType, FaqCategoryType } from '../../type/FaqType'

export type FaqStateType = {
    isLoading: boolean
    list: Array<FaqType>
    categories: Array<FaqCategoryType>
    categoryActive: string | number
    searchValue: string
    ids: Array<number>
    listSearched: Array<FaqType>
}

export interface FaqActions<T = any> extends Action {
    payload?: T
}

export const FaqActionTypes = {
    setLoading: 'FAQ_SET_LOADING',
    fetchCategory: 'FAQ_FETCH_CATEGORY',
    setCategories: 'FAQ_SET_CATEGORIES',
    fetchList: 'FAQ_FETCH_LIST',
    setList: 'FAQ_SET_LIST',
    setSearchValue: 'FAQ_SET_SEARCH_VALUE',
    setCategoryActive: 'FAQ_SET_CATEGORY_ACTIVE',
    setListIds: 'FAQ_SET_LIST_IDS',
    setListSearched: 'FAQ_SET_LIST_SEARCHED',
    changeStateAfterLoading: 'FAQ_CHANGE_STATE_AFTER_LOADING',
}
