import React, { useEffect, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = (): JSX.Element => {
    const location = useLocation()

    useLayoutEffect(() => {
        const { hash } = location
        if (hash) {
            setTimeout(() => {
                const element = document.getElementById(`${hash.slice(1)}-btn`)
                element?.click()
            }, 1000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (location.hash) return
        window.scrollTo(0, 0)
    }, [location])

    return <></>
}

export default ScrollToTop
