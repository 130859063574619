import {
    EventType,
    EventActions,
    EventActionType,
} from '../constant/event.const'

const initialState: EventType = {
    status: '',
    eventCode: '',
    nftId: '',
}

function eventReducers(state = initialState, action: EventActions): EventType {
    switch (action.type) {
        case EventActionType.status:
            return { ...state, status: action.payload }
        case EventActionType.nftId:
            return { ...state, nftId: action.payload }
        case EventActionType.eventCode:
            return { ...state, eventCode: action.payload }
        default:
            return state
    }
}

export default eventReducers
