import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

function Loading(): JSX.Element {
    return (
        <div className="page-loading">
            <div className="page-loading__indicator">
                <Spin
                    indicator={<LoadingOutlined style={{ fontSize: 48 }} />}
                />
            </div>
        </div>
    )
}

export default Loading
