import CmsService from '../cms.service'
import { ArtistInfoType } from '../../type/ArtistInfoType'
import { formatKeys } from '../../utils/filters'
import { QueryParams } from '../../type/QueryParams'
import { ProductType } from '../../type/ProductType'

class ArtistCmsService extends CmsService {
    public getArtist(id: string) {
        return new Promise<ArtistInfoType>((resolve, reject) => {
            this.client
                .get(`piala/artists/${id}`)
                .then((response) => {
                    resolve(response.data.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getArtists(ids: Array<string>, query: QueryParams = {}) {
        return new Promise<Array<ArtistInfoType>>((resolve, reject) => {
            const queryParams = { _sort: 'id:DESC', ...query }
            this.client
                .post(
                    'piala/artists/list',
                    {
                        ids,
                    },
                    {
                        params: queryParams,
                    }
                )
                .then((response) => {
                    const artists = response.data.data.map((artist: any) => {
                        return formatKeys(artist)
                    })
                    resolve(artists as ArtistInfoType[])
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    // public getListArtists(query: QueryParams = {}) {
    //     return new Promise<Array<ArtistInfoType>>((resolve, reject) => {
    //         this.client
    //             .post('piala/artists/list', {
    //                 params: query,
    //             })
    //             .then((response) => {
    //                 const artists = response.data.data.map((artist: any) => {
    //                     return formatKeys(artist)
    //                 })
    //                 resolve(artists as ArtistInfoType[])
    //             })
    //             .catch((error) => {
    //                 reject(error)
    //             })
    //     })
    // }

    public getNewsArtist(id: number | string) {
        return new Promise<any>((resolve, reject) => {
            this.client
                .get(
                    `news-articles?artist_id=${id}&_limit=3&_start=0&_sort=created_at:DESC`
                )
                .then((response) => {
                    resolve(response.data as any)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getAllNewsArtist(id: number | string) {
        return new Promise<any>((resolve, reject) => {
            this.client
                .get(`news-articles?artist_id=${id}&_sort=created_at:DESC`)
                .then((response) => {
                    resolve(response.data as any)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getListNewsArtist(
        id: number | string,
        limit: number,
        start: number
    ) {
        return new Promise<any>((resolve, reject) => {
            this.client
                .get(
                    `news-articles?artist_id=${id}&_limit=${limit}&_start=${start}&_sort=created_at:DESC`
                )
                .then((response) => {
                    resolve(response.data as any)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getArtistBySlug(slug: string) {
        return new Promise<ArtistInfoType>((resolve, reject) => {
            this.client
                .get(`piala/artists/${slug}`)
                .then((response) => {
                    const artist = formatKeys(response.data.data)
                    resolve(artist as ArtistInfoType)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

export default new ArtistCmsService()
