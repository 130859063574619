import {
    ThemeActionsType,
    ThemeActions,
    ThemeStateType,
} from '../constant/theme.const'
import { FrontTheme } from '../../type/FrontTheme'

const initStates: ThemeStateType = {
    isFetchingFrontTheme: false,
    isFetchingGroupTheme: false,
    frontTheme: {} as FrontTheme,
    groupTheme: {} as FrontTheme,
}

function ThemeReducers(
    state = initStates,
    actions: ThemeActions
): ThemeStateType {
    switch (actions.type) {
        case ThemeActionsType.setLoadingFront:
            return { ...state, isFetchingFrontTheme: actions.payload }
        case ThemeActionsType.setLoadingGroup:
            return { ...state, isFetchingGroupTheme: actions.payload }
        case ThemeActionsType.setFrontTheme:
            return { ...state, frontTheme: actions.payload }
        case ThemeActionsType.setGroupTheme:
            return { ...state, groupTheme: actions.payload }
        default:
            return state
    }
}

export default ThemeReducers
