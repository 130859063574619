import React, { Suspense, useEffect, useState } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import Loading from '../components/portal/Loading'
import ArtistHeader from '../components/portal/artist/ArtistHeader'
import artistCmsService from '../services/artist/artistCms.service'
import { useAppDispatch } from '../store/hooks/AppHooks'
import { ArtistActionTypes } from '../store/constant/artist.const'
import { ArtistInfoType } from '../type/ArtistInfoType'

const ArtistComponent = React.lazy(
    () => import('../components/portal/artist/Artist')
)

const BlogComponent = React.lazy(
    () => import('../components/portal/blogs/Blogs')
)

const LiveTicketComponent = React.lazy(
    () => import('../components/portal/liveTickets/LiveTickets')
)

const NewsArtistDetailComponent = React.lazy(
    () => import('../components/portal/news/NewsArtistDetail')
)

function ArtistRoutes(): JSX.Element {
    const { path } = useRouteMatch()
    const { slug } = useParams<{ slug: string }>()
    const [id, setId] = useState('')
    const [artistInfo, setArtistInfo] = useState<ArtistInfoType | null>(null)

    useEffect(() => {
        artistCmsService.getArtistBySlug(slug).then((data) => {
            setId(data.artistId.toString())
            setArtistInfo(data)
        })
    }, [slug])

    return (
        <section className="portal-artist">
            <div className="portal-artist__container">
                {/* Artist Header */}
                <div className="portal-artist__header">
                    {/* <div className="portal-artist__header__nav">
                        {id ? <ArtistHeader id={id} slug={slug} /> : null}
                    </div> */}
                </div>
                <Suspense fallback={<Loading />}>
                    <Switch>
                        <Route exact path={`${path}`} key="portal-artist">
                            <ArtistComponent />
                        </Route>
                        <Route
                            exact
                            path={`${path}/blog`}
                            key="portal-artist__blog"
                        >
                            <BlogComponent id={id} />
                        </Route>
                        <Route
                            exact
                            path={`${path}/personal-talk`}
                            key="portal-artist__personal-talk"
                        >
                            <LiveTicketComponent id={id} artistInfo={null} />
                        </Route>
                        <Route
                            exact
                            path={`${path}/live`}
                            key="portal-artist__personal-talk"
                        >
                            <LiveTicketComponent
                                id={id}
                                artistInfo={artistInfo}
                            />
                        </Route>
                        <Route
                            exact
                            path={`${path}/news`}
                            key="portal-artist__news"
                        >
                            <NewsArtistDetailComponent artistId={id} />
                        </Route>
                    </Switch>
                </Suspense>
            </div>
        </section>
    )
}

export default ArtistRoutes
