import { Action } from 'redux'

export type EventType = {
    status: string
    eventCode: string
    nftId: string
}

export interface EventActions<T = any> extends Action {
    payload?: T
}

export const EventActionType = {
    status: 'EVENT_STATUS',
    nftId: 'NFT_ID',
    eventCode: 'EVENT_CODE',
}
