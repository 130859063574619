import axios, { AxiosInstance, AxiosResponse } from 'axios'

class EcService {
    public static baseURL =
        process.env.REACT_APP_EC_API_URL ?? 'http://localhost:1337'

    public static ecApiKey = process.env.REACT_APP_EC_API_KEY ?? ''

    protected client: AxiosInstance

    constructor() {
        this.client = axios.create({
            baseURL: EcService.baseURL,
            headers: {
                Accept: 'application/json',
            },
        })

        this.client.defaults.headers.authorization = EcService.ecApiKey

        this.client.interceptors.response.use(
            EcService.handleFullFilled,
            EcService.handleRejected
        )
    }

    private static handleFullFilled(response: AxiosResponse): AxiosResponse {
        return response
    }

    private static handleRejected(error: any) {
        return Promise.reject(error)
    }
}

export default EcService
