import React from 'react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from '../../../store/hooks/AppHooks'
import { formatMediaUrl } from '../../../utils/filters'
import { GroupImageSize } from '../../../type/GroupType'
import noAva from '../../../assets/images/portal/no-avatar.png'

type groupImageProps = {
    size: GroupImageSize
    groupId: string
}

function GroupImage({ size, groupId }: groupImageProps): JSX.Element {
    const history = useHistory()
    const { image } = useAppSelector(
        (state) => state.artistGroup.currentGroupDataCms
    )
    const groupIsFetched = useAppSelector(
        (state) => state.artistGroup.isFetched
    )

    const handleRedirectToGroupTop = () => {
        history.push(`/groups/${groupId}`)
    }

    const rendererGroupImage = !_.isEmpty(image) ? (
        <div className="banner-group">
            <div
                className={`portal-groups-group-image__container ${
                    size === 'large'
                        ? 'portal-groups-group-image__container-large'
                        : 'portal-groups-group-image__container-small'
                }`}
            >
                <button
                    className="portal-groups-group-image__image"
                    type="button"
                    onClick={handleRedirectToGroupTop}
                >
                    <div style={{ position: 'relative' }}>
                        <div
                            className="group-img-background"
                            // style={{
                            //     backgroundImage: `url(${
                            //         image
                            //             ? formatMediaUrl(image?.url ?? '')
                            //             : noAva
                            //     })`,
                            // }}
                        />
                        <div
                            className="group-img-center-background"
                            style={{
                                backgroundImage: `url(${
                                    image
                                        ? formatMediaUrl(image?.url ?? '')
                                        : noAva
                                })`,
                            }}
                        />
                        {/* {image ? (
                            <img
                                src={formatMediaUrl(image?.url ?? '')}
                                className="group-img-center"
                                alt="pic"
                            />
                        ) : (
                            <img
                                src={noAva}
                                className="group-img-center"
                                alt="pic"
                            />
                        )} */}
                    </div>
                </button>
            </div>
        </div>
    ) : (
        <div className="banner-group">
            <div
                className={`portal-groups-group-image__container ${
                    size === 'large'
                        ? 'portal-groups-group-image__container-large'
                        : 'portal-groups-group-image__container-small'
                }`}
            >
                <button
                    className="portal-groups-group-image__image"
                    type="button"
                    onClick={handleRedirectToGroupTop}
                >
                    <div style={{ position: 'relative' }}>
                        <div
                            className="group-img-background"
                            style={{
                                backgroundImage: `url(${noAva})`,
                            }}
                        />
                        <div
                            className="group-img-center-background"
                            style={{
                                backgroundImage: `url(${noAva})`,
                            }}
                        />

                        <img
                            src={noAva}
                            className="group-img-center"
                            alt="pic"
                        />
                    </div>
                </button>
            </div>
        </div>
    )

    return (
        <div
            className={`portal-groups-group-image ${
                size === 'large'
                    ? 'portal-groups-group-image-large'
                    : 'portal-groups-group-image-small'
            }`}
        >
            {groupIsFetched ? rendererGroupImage : null}
        </div>
    )
}

export default GroupImage
