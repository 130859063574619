import { Action } from 'redux'
import { BlogType } from '../../type/BlogType'

export type BlogStateType = {
    count: number
    isLoading: boolean
    list: Array<BlogType>
}

export interface BlogActions<T = any> extends Action {
    payload?: T
}

export const BlogActionsType = {
    count: 'BLOG_COUNT',
    fetchList: 'BLOG_FETCH_LIST',
    setList: 'BLOG_SET_LIST',
    setLoading: 'BLOG_SET_LOADING',
    setCount: 'BLOG_SET_COUNT',
}
