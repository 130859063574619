import { all, fork, call, put, takeLatest, select } from 'redux-saga/effects'
import _ from 'lodash'
import { NewsPaginationType, NewsType } from '../../type/NewsType'
import NewsService from '../../services/news/index'
import { NewsActions, NewsActionTypes } from '../constant/news.const'
import { rootState } from '..'

export function* getNewsOnTop() {
    try {
        const articles: NewsType[] = yield call(
            NewsService.get.bind(NewsService),
            {
                _sort: 'id:DESC',
                _limit: 3,
            }
        )
        yield put({
            type: NewsActionTypes.setNewsOnTop,
            payload: articles,
        })
    } catch (e) {
        console.error(e)
    }
}

export function* getNews(actions: NewsActions) {
    try {
        yield put({
            type: NewsActionTypes.setLoading,
            payload: true,
        })
        const paginationNewsList: NewsPaginationType = yield select(
            (state: rootState) => state.news.pagination
        )
        const offset = _.isUndefined(actions.payload?.currentPage)
            ? 0
            : (actions.payload?.currentPage - 1) * paginationNewsList.limit

        const articles: NewsType[] = yield call(
            NewsService.get.bind(NewsService),
            {
                _sort: 'id:DESC',
                _limit: 10,
                _start: offset,
            }
        )
        yield put({
            type: NewsActionTypes.setNewsAll,
            payload: articles,
        })
        yield put({
            type: NewsActionTypes.setLoading,
            payload: false,
        })
    } catch (e) {
        console.error(e)
    }
}

export function* getNewsCount() {
    try {
        const count: number = yield call(NewsService.getCount.bind(NewsService))
        yield put({
            type: NewsActionTypes.setCount,
            payload: count,
        })
    } catch (e) {
        console.error(e)
    }
}

export function* newsSaga() {
    yield all([
        fork(getNewsOnTop),
        fork(getNewsCount),
        takeLatest(NewsActionTypes.fetchNewsAll, getNews),
    ])
}
