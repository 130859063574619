import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import Loading from '../components/portal/Loading'
import routes from './index'
import Footer from '../components/portal/Footer'
import PointGage from '../components/portal/PointGage'
import Protected from '../components/Protected'
import { useAppSelector } from '../store/hooks/AppHooks'
import BottomNav from '../components/portal/BottomNav'

export type PortalRoutesProps = {
    prefix: string
}

function PortalRoutes({ prefix }: PortalRoutesProps): JSX.Element {
    const isAuth = useAppSelector((state) => state.user.isAuth)
    const routesRenderer = routes
        .filter(({ prefix: srcPrefix }) => {
            return srcPrefix === prefix
        })
        .map(({ path, name, component, exact, meta }) => {
            if (meta !== undefined && meta?.protected && !isAuth) {
                return (
                    <Route
                        key={name}
                        exact
                        path={`${prefix}${path}`}
                        component={Protected}
                    />
                )
            }
            return (
                <Route
                    key={name}
                    exact={exact ?? true}
                    path={`${prefix}${path}`}
                    component={component}
                />
            )
        })

    return (
        <Suspense fallback={<Loading />}>
            <Switch>{routesRenderer}</Switch>
            {/* <PointGage /> */}
            <Footer />
            <BottomNav />
        </Suspense>
    )
}

export default PortalRoutes
