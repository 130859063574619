import { all, call, put, takeLatest } from 'redux-saga/effects'
import { FrontTheme } from '../../type/FrontTheme'
import SettingsService from '../../services/settings'
import { ThemeActionsType } from '../constant/theme.const'

export function* fetchFrontTheme() {
    try {
        yield put({
            type: ThemeActionsType.setLoadingFront,
            payload: true,
        })

        const frontTheme: FrontTheme = yield call(
            SettingsService.getTheme.bind(SettingsService),
            'front-theme'
        )

        yield put({
            type: ThemeActionsType.setFrontTheme,
            payload: frontTheme,
        })
    } catch (e) {
        console.error(e)
    } finally {
        yield put({
            type: ThemeActionsType.setLoadingFront,
            payload: false,
        })
    }
}

/**
 * DEPRECATED: the group color theme will be setting inside group settings
 */
export function* fetchGroupTopTheme() {
    try {
        yield put({
            type: ThemeActionsType.setLoadingGroup,
            payload: true,
        })

        const groupTopTheme: FrontTheme = yield call(
            SettingsService.getTheme.bind(SettingsService),
            'group-top-theme'
        )

        yield put({
            type: ThemeActionsType.setGroupTheme,
            payload: groupTopTheme,
        })
    } catch (e) {
        console.error(e)
    } finally {
        yield put({
            type: ThemeActionsType.setLoadingGroup,
            payload: false,
        })
    }
}

export function* themeSaga() {
    yield all([
        // takeLatest(ThemeActionsType.fetchFrontTheme, fetchFrontTheme),
        // DEPRECATED: the group color theme will be setting inside group settings
        // takeLatest(ThemeActionsType.fetchGroupTheme, fetchGroupTopTheme),
    ])
}
