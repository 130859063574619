import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Carousel, message } from 'antd'
import EcService from '../../../services/ec.service'
import artistCmsService from '../../../services/artist/artistCms.service'
import artistService from '../../../services/portal/artist.service'
import useGetWidthSize from '../../../hook/useGetWidthSize'

enum ArtistHeaderMenuTypes {
    INFO = 'info',
    CLUB = 'club',
    SHOP = 'shop',
    BLOG = 'blog',
    LIVE = 'live',
    PERSONAL_TALK = 'personal-talk',
}

type ArtistHeaderMenuItemProps = {
    type: ArtistHeaderMenuTypes
    groupId: string
    artistSlug: string
    artistId: string
}

function ArtistHeaderMenuItem({
    type,
    groupId,
    artistSlug,
    artistId,
}: ArtistHeaderMenuItemProps): JSX.Element {
    const { pathname } = useLocation()
    const path = pathname.split(`/groups/${groupId}/artist/${artistSlug}/`)[1]

    switch (type) {
        case ArtistHeaderMenuTypes.INFO:
            return (
                <div
                    className={`portal-groups__menu__nav__item ${
                        !path ? 'active' : ''
                    }`}
                >
                    <Link
                        to={`/groups/${groupId}/artist/${artistSlug}`}
                        className="nav__item"
                    >
                        Info
                    </Link>
                </div>
            )
        case ArtistHeaderMenuTypes.CLUB:
            return (
                <div
                    className={`portal-groups__menu__nav__item ${
                        !path ? 'active' : ''
                    }`}
                >
                    <Link
                        to={`/groups/${groupId}/artist/${artistSlug}`}
                        className="nav__item"
                    >
                        Club
                    </Link>
                </div>
            )
        case ArtistHeaderMenuTypes.BLOG:
            return (
                <div
                    className={`portal-groups__menu__nav__item ${
                        path === 'blog' ? 'active' : ''
                    }`}
                >
                    <Link
                        to={`/groups/${groupId}/artist/${artistSlug}/blogs`}
                        className="nav__item"
                    >
                        Blog
                    </Link>
                </div>
            )
        case ArtistHeaderMenuTypes.SHOP:
            return (
                <div className="portal-groups__menu__nav__item">
                    <a
                        href={`${EcService.baseURL}/products/list?category_id=${artistId}`}
                        className="nav__item"
                    >
                        Shop
                    </a>
                </div>
            )
        case ArtistHeaderMenuTypes.PERSONAL_TALK:
            return (
                <div
                    className={`portal-groups__menu__nav__item ${
                        path === 'personal-talk' ? 'active' : ''
                    }`}
                >
                    <Link
                        to={`/groups/${groupId}/artist/${artistSlug}/personal-talk`}
                        className="nav__item"
                    >
                        Personal Talk
                    </Link>
                </div>
            )
        case ArtistHeaderMenuTypes.LIVE:
            return (
                <div
                    className={`portal-groups__menu__nav__item ${
                        path === 'live' ? 'active' : ''
                    }`}
                >
                    <Link
                        to={`/groups/${groupId}/artist/${artistSlug}/live`}
                        className="nav__item"
                    >
                        Live
                    </Link>
                </div>
            )
        default:
            return <></>
    }
}

export type ArtistDetailHeaderPropsType = {
    groupId: string
    artistSlug: string
}

function ArtistDetailHeader({
    groupId,
    artistSlug,
}: ArtistDetailHeaderPropsType): JSX.Element {
    const { pathname } = useLocation()
    const [artistCms, setArtistCms] = useState<any>(undefined)
    const [artistMenu, setArtistMenu] = useState<any[]>([])

    const slug = useMemo((): string => {
        const params = pathname.split('/')
        const value =
            (params.length === 5 || params.length === 6) && params[3] !== 'news'
                ? params[4]
                : undefined
        return value || ''
    }, [pathname])

    useEffect(() => {
        artistCmsService
            .getArtistBySlug(slug)
            .then((data) => {
                setArtistCms(data)
            })
            .catch((error) => {
                // message.error('データの読み込みに失敗しました。')
            })
    }, [slug])

    useEffect(() => {
        if (artistCms && artistCms.artistId) {
            artistService.getMenus(artistCms.artistId).then((data) => {
                setArtistMenu(data)
            })
        }
    }, [artistCms])

    let slideToShow = artistMenu?.length >= 4 ? 4 : artistMenu?.length
    if (useGetWidthSize().width <= 568) {
        slideToShow = 5
    } else {
        slideToShow = 15
    }
    const menuItemsRenderer = artistMenu?.map((menu, idx) => {
        let type = ArtistHeaderMenuTypes.INFO
        switch (menu.menuName) {
            case ArtistHeaderMenuTypes.INFO:
                type = ArtistHeaderMenuTypes.INFO
                break
            case ArtistHeaderMenuTypes.CLUB:
                type = ArtistHeaderMenuTypes.CLUB
                break
            case ArtistHeaderMenuTypes.BLOG:
                type = ArtistHeaderMenuTypes.BLOG
                break
            case ArtistHeaderMenuTypes.SHOP:
                type = ArtistHeaderMenuTypes.SHOP
                break
            case ArtistHeaderMenuTypes.LIVE:
                type = ArtistHeaderMenuTypes.LIVE
                break
            case ArtistHeaderMenuTypes.PERSONAL_TALK:
                type = ArtistHeaderMenuTypes.PERSONAL_TALK
                break
            default:
                return null
        }

        return (
            <ArtistHeaderMenuItem
                type={type}
                groupId={groupId}
                artistSlug={artistSlug}
                key={idx.toString()}
                artistId={artistCms.artistId}
            />
        )
    })

    return (
        <div className="portal-groups__menu">
            <div className="portal-groups__menu__nav">
                <div style={{ width: '414px' }}>
                    {artistMenu?.length > 0 ? (
                        <Carousel
                            variableWidth
                            dots={false}
                            swipeToSlide
                            slidesToShow={slideToShow}
                            draggable
                            infinite={false}
                        >
                            {menuItemsRenderer}
                        </Carousel>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default ArtistDetailHeader
