import CmsService from './cms.service'
import { UserType } from '../type/UserType'

class UserService extends CmsService {
    public getProfile() {
        return new Promise<UserType>((resolve, reject) => {
            this.client
                .get('users/me')
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

export default new UserService()
