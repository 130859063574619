import EcService from './ec.service'
import { PointType } from '../type/PointType'
import { QueryParams } from '../type/QueryParams'

export interface PointQueryParams extends QueryParams {
    id: string
    mode: number
}
class PointService extends EcService {
    public get(id: string) {
        return new Promise<PointType>((resolve, reject) => {
            this.client
                .get('mgnPoint', {
                    params: { id, mode: 0 },
                })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}
export default new PointService()
