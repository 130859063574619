import CmsService from '../cms.service'
import { FaqType, FaqCategoryType } from '../../type/FaqType'

class FaqCmsService extends CmsService {
    public getFaq(id: number | string) {
        return new Promise<FaqType>((resolve, reject) => {
            this.client
                .get(`faqs/${id}`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getFaqs() {
        return new Promise<FaqType[]>((resolve, reject) => {
            this.client
                .get('faqs')
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getFaqCategories() {
        return new Promise<FaqCategoryType[]>((resolve, rejecct) => {
            this.client
                .get('faq-categories')
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    rejecct(error)
                })
        })
    }
}

export default new FaqCmsService()
