import React from 'react'
import { Empty, Skeleton, Space } from 'antd'
import { Link, useLocation, useParams } from 'react-router-dom'
import EcService from '../../../services/ec.service'
import { useAppSelector } from '../../../store/hooks/AppHooks'

enum ArtistGroupHeaderMenuTypes {
    INFO = 'info',
    FC = 'FC',
    SHOP = 'shop',
    BLOG = 'blog',
    LIVE = 'live',
    NFT = 'nft',
    PERSONAL_TALK = 'personal-talk',
}

type ArtistGroupHeaderMenuItemProps = {
    type: ArtistGroupHeaderMenuTypes
    id: string
    categoryId: number
}

function ArtistGroupHeaderMenuItem({
    type,
    id,
    categoryId,
}: ArtistGroupHeaderMenuItemProps): JSX.Element {
    const { slug: idRouteParam } = useParams<{ slug: string }>()
    const { pathname } = useLocation()
    const path = pathname.split(`/groups/${idRouteParam}/`)[1]

    switch (type) {
        case ArtistGroupHeaderMenuTypes.INFO:
            return (
                <div
                    className={`portal-groups__menu__nav__item ${
                        !path || path.split('/')[0] === 'artist' ? 'active' : ''
                    }`}
                >
                    <Link to={`/groups/${id}`} className="nav__item">
                        Info
                    </Link>
                </div>
            )
        case ArtistGroupHeaderMenuTypes.FC:
            return (
                <div
                    className={`portal-groups__menu__nav__item ${
                        path === 'FC' ? 'active' : ''
                    }`}
                >
                    <Link
                        to={`/groups/${id}/FC`}
                        className="nav__item"
                        onClick={() => {
                            localStorage.setItem('clickTabFcGroup', '1')
                        }}
                    >
                        クラブ
                    </Link>
                </div>
            )
        case ArtistGroupHeaderMenuTypes.BLOG:
            return (
                <div
                    className={`portal-groups__menu__nav__item ${
                        path === 'blog' ? 'active' : ''
                    }`}
                >
                    <Link to={`/groups/${id}/blog`} className="nav__item">
                        Blog
                    </Link>
                </div>
            )
        case ArtistGroupHeaderMenuTypes.SHOP:
            return (
                <div className="portal-groups__menu__nav__item">
                    <a
                        className="nav__item"
                        href={`${EcService.baseURL}/products/list?category_id=${categoryId}`}
                    >
                        Shop
                    </a>
                </div>
            )
        case ArtistGroupHeaderMenuTypes.PERSONAL_TALK:
            return (
                <div
                    className={`portal-groups__menu__nav__item ${
                        path === 'personal-talk' ? 'active' : ''
                    }`}
                >
                    <Link
                        to={`/groups/${id}/personal-talk`}
                        className="nav__item"
                    >
                        Personal Talk
                    </Link>
                </div>
            )
        case ArtistGroupHeaderMenuTypes.LIVE:
            return (
                <div
                    className={`portal-groups__menu__nav__item ${
                        path === 'live' ? 'active' : ''
                    }`}
                >
                    <Link to={`/groups/${id}/live`} className="nav__item">
                        Live
                    </Link>
                </div>
            )
        case ArtistGroupHeaderMenuTypes.NFT:
            return (
                <div
                    className={`portal-groups__menu__nav__item ${
                        path === 'nft' ? 'active' : ''
                    }`}
                >
                    <Link to={`/groups/${id}/nft`} className="nav__item">
                        NFT
                    </Link>
                </div>
            )
        default:
            return <></>
    }
}

export type ArtistGroupHeaderPropsType = {
    id: string
    menus: Array<string>
    groupCategoryId: number
}

function ArtistGroupHeader({
    id,
    menus,
    groupCategoryId,
}: ArtistGroupHeaderPropsType): JSX.Element {
    const { isLoading } = useAppSelector((state) => state.artistGroup)

    const menuItemsRenderer = menus?.map((menu, idx) => {
        let type = ArtistGroupHeaderMenuTypes.INFO
        switch (menu) {
            case ArtistGroupHeaderMenuTypes.INFO:
                type = ArtistGroupHeaderMenuTypes.INFO
                break
            case ArtistGroupHeaderMenuTypes.FC:
                type = ArtistGroupHeaderMenuTypes.FC
                break
            case ArtistGroupHeaderMenuTypes.BLOG:
                type = ArtistGroupHeaderMenuTypes.BLOG
                break
            case ArtistGroupHeaderMenuTypes.SHOP:
                type = ArtistGroupHeaderMenuTypes.SHOP
                break
            case ArtistGroupHeaderMenuTypes.LIVE:
                type = ArtistGroupHeaderMenuTypes.LIVE
                break
            case ArtistGroupHeaderMenuTypes.NFT:
                type = ArtistGroupHeaderMenuTypes.NFT
                break
            case ArtistGroupHeaderMenuTypes.PERSONAL_TALK:
                type = ArtistGroupHeaderMenuTypes.PERSONAL_TALK
                break
            default:
                return null
        }

        return (
            <div key={idx.toString()}>
                <ArtistGroupHeaderMenuItem
                    type={type}
                    key={idx.toString()}
                    categoryId={groupCategoryId}
                    id={id}
                />
            </div>
        )
    })

    return (
        <div className="portal-groups__menu">
            <div className="portal-groups__menu__nav">
                <div
                    className="portal-groups-list-menu"
                    style={{ width: '100%' }}
                >
                    {!isLoading ? (
                        <>
                            {menus?.length > 0 ? (
                                <div
                                    className={`flex ${
                                        menus?.length < 4
                                            ? ' justify-center-sp'
                                            : ''
                                    } overflow-auto pb-10`}
                                >
                                    {menuItemsRenderer}
                                </div>
                            ) : (
                                <Space />
                            )}
                        </>
                    ) : (
                        <Space>
                            <Skeleton.Button active />
                        </Space>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ArtistGroupHeader
