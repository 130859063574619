import CmsService from '../cms.service'
import { BannerType } from '../../type/BannerType'
import { QueryParams } from '../../type/QueryParams'

class BannerService extends CmsService {
    param: QueryParams

    constructor() {
        super()
        this.param = { _sort: 'sort_order:ASC' }
    }

    public getBanners() {
        return new Promise<BannerType[]>((resolve, reject) => {
            this.client
                .get('banners', { params: this.param })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getFooterBanners() {
        return new Promise<BannerType[]>((resolve, reject) => {
            this.client
                .get('footer-banners', { params: this.param })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getNftMallBanner() {
        return new Promise<BannerType>((resolve, reject) => {
            this.client
                .get('nft-mall-banner')
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getMiddleBanners() {
        return new Promise<BannerType[]>((resolve, reject) => {
            this.client
                .get('middle-banners', { params: this.param })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

export default new BannerService()
