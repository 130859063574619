import { fork, all } from 'redux-saga/effects'
import { artistSaga } from './artist.saga'
import { userSaga } from './user.saga'
import { appSaga } from './app.saga'
import { blogSaga } from './blog.saga'
import { faqSaga } from './faq.saga'
import { artistGroupSaga } from './group.saga'
import { productSaga } from './product.saga'
import { themeSaga } from './theme.saga'
import { newsSaga } from './news.saga'
import { gachaSaga } from './gacha.saga'

const sagas = [
    artistSaga,
    userSaga,
    appSaga,
    blogSaga,
    faqSaga,
    artistGroupSaga,
    productSaga,
    themeSaga,
    newsSaga,
    gachaSaga,
]

export default function* rootSaga(): Generator {
    yield all(sagas.map((saga) => fork(saga)))
}
