import { all, fork, call, put, takeLatest } from 'redux-saga/effects'
import { AppType } from '../../type/AppType'
import SettingsService from '../../services/settings'
import CarouselService from '../../services/portal/carousel.service'
import { CarouselType } from '../../type/CarouselType'
import { AppActionsType } from '../constant/app.const'
import BannerService from '../../services/portal/banner.service'
import { BannerType } from '../../type/BannerType'

export function* getSettings() {
    try {
        yield put({
            type: AppActionsType.setLoading,
            payload: true,
        })
        const setting: AppType = yield call(
            SettingsService.getSetting.bind(SettingsService)
        )
        yield put({
            type: AppActionsType.setSettings,
            payload: setting,
        })
        yield put({
            type: AppActionsType.setLoading,
            payload: false,
        })
    } catch (e) {
        console.error(e)
    }
}

export function* getCarousel() {
    try {
        const carousels: CarouselType = yield call(
            CarouselService.getCarousels.bind(CarouselService)
        )
        yield put({
            type: AppActionsType.setCarousel,
            payload: carousels,
        })
    } catch (e) {
        console.error(e)
    }
}

export function* getBanners() {
    try {
        const banners: BannerType[] = yield call(
            BannerService.getBanners.bind(BannerService)
        )
        yield put({
            type: AppActionsType.setBanner,
            payload: banners,
        })
    } catch (e) {
        console.error(e)
    }
}

export function* getFooterBanners() {
    try {
        const banners: BannerType[] = yield call(
            BannerService.getFooterBanners.bind(BannerService)
        )
        yield put({
            type: AppActionsType.setFooterBanner,
            payload: banners,
        })
    } catch (e) {
        console.error(e)
    }
}

export function* getNftMallBanner() {
    try {
        const banners: BannerType[] = yield call(
            BannerService.getNftMallBanner.bind(BannerService)
        )
        yield put({
            type: AppActionsType.setNftMallBanner,
            payload: banners,
        })
    } catch (e) {
        console.error(e)
    }
}

export function* getMiddleBanners() {
    try {
        const banners: BannerType[] = yield call(
            BannerService.getMiddleBanners.bind(BannerService)
        )
        yield put({
            type: AppActionsType.setMiddleBanner,
            payload: banners,
        })
    } catch (e) {
        console.error(e)
    }
}

function* fetchAll() {
    yield all([
        fork(getSettings),
        fork(getCarousel),
        // fork(getBanners),
        // fork(getFooterBanners),
        fork(getNftMallBanner),
        fork(getMiddleBanners),
    ])
}

export function* appSaga() {
    yield takeLatest(AppActionsType.fetchAll, fetchAll)
}
