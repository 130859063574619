import _ from 'lodash'
import qs from 'qs'
import CmsService from '../cms.service'
import { CarouselType } from '../../type/CarouselType'
import { formatKeys } from '../../utils/filters'

class CarouselService extends CmsService {
    public getCarousels() {
        return new Promise<Array<CarouselType>>((resolve, reject) => {
            const queryParams = qs.stringify({
                _sort: 'sort_order:DESC,id:DESC',
            })
            this.client
                .get(`carousels?${queryParams}`)
                .then((response) => {
                    const data = _.map(response.data, (item) => {
                        return formatKeys(item)
                    })
                    resolve(data as CarouselType[])
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

export default new CarouselService()
