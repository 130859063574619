import { Action } from 'redux'
import { CarouselType } from '../../type/CarouselType'
import { GachaCmsType } from '../../type/GachaCmsType'
import { GachaType } from '../../type/GachaType'

export type GachaStateType = {
    isCarouselLoading: boolean
    isCollectionLoading: boolean
    gachasCms: Array<GachaCmsType>
    gachasEc: Array<GachaType>
    carousel: Array<CarouselType>
    countGachas: number
}

export interface GachaActions<T = any> extends Action {
    payload?: T
}

export const GachaActionTypes = {
    fetchGachasCms: 'GACHA_FETCH_GACHAS_CMS',
    fetchCarousel: 'GACHA_FETCH_CAROUSEL',
    fetchGachasEc: 'GACHA_FETCH_GACHAS_EC',
    fetchCountGachas: 'GACHA_FETCH_COUNT_GACHAS',
    setCarouselLoading: 'GACHA_SET_CAROUSEL_LOADING',
    setCollectionLoading: 'GACHA_SET_COLLECTION_LOADING',
    setGachasCms: 'GACHA_SET_GACHAS_CMS',
    setCarousel: 'GACHA_SET_CAROUSEL',
    setGachasEc: 'GACHA_SET_GACHAS_EC',
    setCountGachas: 'GACHA_SET_COUNT_GACHAS',
    fetchGachas: 'GACHA_FETCH_GACHAS',
}
