import { Action } from 'redux'
import { NewsPaginationType, NewsType } from '../../type/NewsType'

export type NewsStateType = {
    isLoading: boolean
    newsOnTop: NewsType[]
    newsAll: NewsType[]
    count: number
    pagination: NewsPaginationType
}

export interface NewsActions<T = any> extends Action {
    payload?: T
}

export const NewsActionTypes = {
    setLoading: 'NEWS_SET_LOADING',
    fetchNewsOnTop: 'NEWs_FETCH_NEWS_ON_TOP',
    setNewsOnTop: 'NEWs_SET_NEWS_ON_TOP',
    fetchNewsAll: 'NEWS_FETCH_NEWS_ALL',
    setNewsAll: 'NEWS_SET_NEWS_ALL',
    fetchCount: 'NEWS_FETCH_COUNT',
    setCount: 'NEWS_SET_COUNT',
    setPagination: 'NEWS_SET_PAGINATION',
    resetPagination: 'NEWS_RESET_PAGINATION',
}
