import React, {
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import {
    Route,
    Switch,
    useHistory,
    useLocation,
    useParams,
    useRouteMatch,
} from 'react-router-dom'
import { Empty, message, Modal } from 'antd'
import { AxiosError } from 'axios'
import _ from 'lodash'

import Loading from '../components/portal/Loading'
import { useAppDispatch, useAppSelector } from '../store/hooks/AppHooks'
import { GroupEc } from '../type/GroupType'
import { ArtistGroupActionType } from '../store/constant/group.const'
import ArtistGroupHeader from '../components/portal/groups/ArtistGroupHeader'
import GroupImage from '../components/portal/groups/GroupImage'
import ArtistDetailHeader from '../components/portal/groups/ArtistDetailHeader'
import ArtistCmsService from '../services/artist/artistCms.service'
import { ArtistInfoType, initial } from '../type/ArtistInfoType'
import GroupFavoriteButton from '../components/portal/groups/actions/GroupFavorite'
import UserService from '../services/user.service'
import useGetWidthSize from '../hook/useGetWidthSize'
import { formatMediaUrl } from '../utils/filters'

const GroupTopComponent = React.lazy(
    () => import('../components/portal/groups/GroupTop')
)

const GroupBlogComponent = React.lazy(
    () => import('../components/portal/blogs/Blogs')
)

const GroupFCComponent = React.lazy(() => import('../components/portal/fc/FC'))

const NewsList = React.lazy(
    () => import('../components/portal/groups/news/NewsList')
)

const LiveTicketComponent = React.lazy(
    () => import('../components/portal/liveTickets/LiveTickets')
)

const GroupArtistDetailComponent = React.lazy(
    () => import('../components/portal/groups/GroupArtistDetail')
)

const GroupNewsDetailComponent = React.lazy(
    () => import('../components/portal/groups/news/GroupNewsDetail')
)

const GroupBlogDetailComponent = React.lazy(
    () => import('../components/portal/blogs/BlogDetail')
)

const BlogsComponent = React.lazy(
    () => import('../components/portal/blogs/Blogs')
)

function ArtistGroupRoutes(): JSX.Element {
    const location = useLocation()
    const history = useHistory()
    const { path } = useRouteMatch()
    const { slug } = useParams<{ slug: string }>()
    const dispatch = useAppDispatch()
    const [artist, setArtist] = useState<ArtistInfoType>(initial)
    const [me, setMe] = useState<any>()
    const { width } = useGetWidthSize()

    const fetchingErrors = useAppSelector((state) => state.artistGroup.errors)
    const { groupsEc } = useAppSelector((state) => state.artistGroup)
    const { ecId } = useAppSelector(
        (state) => state.artistGroup.currentGroupDataCms
    )
    const { id } = useAppSelector(
        (state) => state.artistGroup.currentGroupDataCms
    )
    const { image } = useAppSelector(
        (state) => state.artistGroup.currentGroupDataCms
    )

    // const isAuth = useAppSelector((state) => state.user.isAuth)
    // const isFavoriteAdded = useAppSelector(
    //     (state) => state.artistGroup.isFavorited
    // )
    // const customerId = useAppSelector((state) => state.user.me.ecCubeId)

    const [groupDataFromEcTarget, setGroupDataFromEcTarget] = useState<GroupEc>(
        {} as GroupEc
    )

    const { pathname } = useLocation()
    const currentPageIsGroupTop = !pathname.split(`/groups/${id}/`)[1]

    const artistName = useMemo(() => {
        const params = pathname.split('/')
        const value =
            (params.length === 5 || params.length === 6) && params[3] !== 'news'
                ? params[4]
                : undefined
        return value
    }, [pathname])

    useEffect(() => {
        if (fetchingErrors.length > 0) {
            fetchingErrors.forEach((error: AxiosError) => {
                if (error.response || error.request) {
                    history.push('/')
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchingErrors])

    useEffect(() => {
        dispatch({
            type: ArtistGroupActionType.fetchFromCms,
            payload: slug,
        })
    }, [dispatch, slug])

    useEffect(() => {
        if (id) {
            // Fetching the group-top's top carousel
            dispatch({
                type: ArtistGroupActionType.fetchGroupTopSlides,
                payload: { groupId: id },
            })
            // Fetching the group-top's middle carousel
            dispatch({
                type: ArtistGroupActionType.fetchGroupTopMiddleSlides,
                payload: { groupId: id },
            })
        }
    }, [dispatch, id])

    useEffect(() => {
        const groupDataFromEc = _.find(groupsEc, ({ id: ecIdStoredOnEc }) => {
            return ecIdStoredOnEc === ecId
        })
        if (!_.isUndefined(groupDataFromEc)) {
            setGroupDataFromEcTarget(groupDataFromEc)
            // if (groupDataFromEc?.menu?.length > 0) {
            //     if (
            //         groupDataFromEc?.menu.find(
            //             (item: string) =>
            //                 item && location.pathname.includes(item)
            //         )
            //     ) {
            //         const menuItem = groupDataFromEc?.menu.find(
            //             (item: string) =>
            //                 item && location.pathname.includes(item)
            //         )
            //         history.push(`/groups/${slug}/${menuItem}`)
            //     } else if (groupDataFromEc?.menu[0] === 'info') {
            //         history.push(`/groups/${slug}`)
            //     } else {
            //         history.push(`/groups/${slug}/${groupDataFromEc?.menu[0]}`)
            //     }
            // }
        }
    }, [ecId, groupsEc, history, slug])

    useEffect(() => {
        if (artistName) {
            ArtistCmsService.getArtistBySlug(artistName)
                .then((data) => {
                    setArtist(data)
                })
                .catch((error) => {
                    // message.error('データの読み込みに失敗しました。')
                    // history.push('/')
                })
        }
    }, [artistName])

    useEffect(() => {
        if (pathname.includes('/FC')) {
            UserService.getProfile()
                .then((data) => {
                    setMe(data)
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }, [pathname])

    const handleCheckingGroupName = useCallback(
        (name = '') => {
            if (name.length > 30 && width < 768)
                return <div>{name.slice(0, 30)}...</div>
            return <div>{name}</div>
        },
        [width]
    )

    return (
        <section className="portal-groups">
            <div className="portal-groups__container">
                {id ? (
                    <>
                        {currentPageIsGroupTop ? (
                            <GroupImage size="large" groupId={slug} />
                        ) : (
                            <GroupImage size="small" groupId={slug} />
                        )}
                        <div className="portal-groups-icon-heart">
                            <p className="font-size-26-i line-height-33 pb-5 name-title-group">
                                {handleCheckingGroupName(
                                    groupDataFromEcTarget.name
                                )}
                            </p>
                            {/* <a className="portal-groups-icon-heart-fav">
                                <img src={heart} alt="" />
                                <p>お気に入り登録する</p>
                            </a> */}
                            {/* {handleRenderButtonFavorite()} */}
                            <GroupFavoriteButton />
                        </div>
                        {id && !artistName ? (
                            <ArtistGroupHeader
                                id={slug}
                                menus={groupDataFromEcTarget.menu}
                                groupCategoryId={
                                    groupDataFromEcTarget.groupCategoryId
                                }
                            />
                        ) : null}
                        {id && artistName ? (
                            <ArtistDetailHeader
                                groupId={slug}
                                artistSlug={`${artistName}`}
                            />
                        ) : null}
                        <Suspense fallback={<Loading />}>
                            <Switch>
                                <Route exact path={`${path}`} key="group__top">
                                    <GroupTopComponent
                                        artistId={_.toString(
                                            groupDataFromEcTarget.groupCategoryId
                                        )}
                                    />
                                </Route>
                                <Route
                                    exact
                                    path={`${path}/artist/:artistId`}
                                    key="group__artist-detail"
                                >
                                    <GroupArtistDetailComponent />
                                </Route>
                                <Route
                                    exact
                                    path={`${path}/blog`}
                                    key="portal-artist__blog"
                                >
                                    <GroupBlogComponent
                                        id={_.toString(
                                            groupDataFromEcTarget.groupCategoryId
                                        )}
                                        groupId={ecId.toString()}
                                        // groupId={id.toString()}
                                    />
                                </Route>
                                <Route
                                    exact
                                    path={`${path}/FC`}
                                    key="group-artist__FC"
                                >
                                    <GroupFCComponent
                                        custormerId={me?.ecCubeId}
                                        artistGroupId={ecId}
                                        artistId={_.toString(
                                            groupDataFromEcTarget.groupCategoryId
                                        )}
                                    />
                                </Route>
                                <Route
                                    exact
                                    path={`${path}/news`}
                                    key="group_news_list"
                                >
                                    <NewsList />
                                </Route>
                                <Route
                                    exact
                                    path={`${path}/blog/:blogId`}
                                    key="group__blog-detail"
                                >
                                    <div className="portal-groups-blog-detail__wrapper">
                                        <GroupBlogDetailComponent />
                                    </div>
                                </Route>
                                <Route
                                    exact
                                    path={`${path}/artist/${artistName}/blogs`}
                                    key="artist-blogs"
                                >
                                    <BlogsComponent
                                        id={`${artist?.artistId?.toString()}`}
                                        groupId={`${id}`}
                                    />
                                </Route>
                                <Route
                                    exact
                                    path={`${path}/artist/${artistName}/live`}
                                    key="live-blogs"
                                >
                                    <LiveTicketComponent
                                        id={`${artist?.artistId?.toString()}`}
                                        artistInfo={null}
                                    />
                                </Route>
                                <Route
                                    exact
                                    path={`${path}/artist/${artistName}/personal-talk`}
                                    key="personal-talk-blogs"
                                >
                                    <LiveTicketComponent
                                        id={`${artist?.artistId?.toString()}`}
                                        artistInfo={null}
                                    />
                                </Route>
                                <Route
                                    exact
                                    path={`${path}/personal-talk`}
                                    key="group__personal-talk"
                                >
                                    <LiveTicketComponent
                                        id={_.toString(
                                            groupDataFromEcTarget?.groupCategoryId
                                        )}
                                        artistInfo={null}
                                    />
                                </Route>
                                <Route
                                    exact
                                    path={`${path}/live`}
                                    key="group__live"
                                >
                                    <LiveTicketComponent
                                        id={_.toString(
                                            groupDataFromEcTarget?.groupCategoryId
                                        )}
                                        artistInfo={null}
                                    />
                                </Route>
                                <Route
                                    exact
                                    path={`${path}/news/:newsId`}
                                    key="group__news-detail"
                                >
                                    <GroupNewsDetailComponent />
                                </Route>
                            </Switch>
                        </Suspense>
                    </>
                ) : (
                    <Empty
                        style={{ marginTop: '30px' }}
                        description="申し訳ございませんが、検索したいグループは見つかりませんでした。"
                    />
                )}
            </div>
        </section>
    )
}

export default ArtistGroupRoutes
