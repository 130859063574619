/* eslint-disable import/no-duplicates */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { HeartFilled } from '@ant-design/icons'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../store/hooks/AppHooks'
import { ArtistGroupActionType } from '../../../../store/constant/group.const'
import heart from '../../../../assets/images/icon_heart.png'

type GroupFavoriteProps = {
    id?: string
}

const GroupFavoriteButton = ({ id }: GroupFavoriteProps): JSX.Element => {
    const antIcon = (
        <LoadingOutlined style={{ fontSize: 16, verticalAlign: 'top' }} spin />
    )

    const dispatch = useAppDispatch()
    const isAuth = useAppSelector((state) => state.user.isAuth)
    const customerId = useAppSelector((state) => state.user.me.ecCubeId)
    const favorites = useAppSelector((state) => state.artistGroup.favorites)
    const isFavorited = useAppSelector((state) => state.artistGroup.isFavorited)
    const isLoading = useAppSelector(
        (state) => state.artistGroup.favoriteLoading
    )
    const group = useAppSelector(
        (state) => state.artistGroup.currentGroupDataCms
    )

    // Fetch the favorite list from ec cube
    useEffect(() => {
        if (isAuth && customerId && group.ecId)
            dispatch({
                type: ArtistGroupActionType.fetchFavorites,
                payload: {
                    customerId,
                    groupId: group.ecId,
                },
            })
    }, [group, customerId, isAuth, dispatch])

    // Checking this group is favorite or not
    useEffect(() => {
        const search = favorites.some(({ customerId: userId, groupId }) => {
            return (
                customerId.toString() === userId.toString() &&
                group.ecId.toString() === groupId.toString()
            )
        })
        dispatch({
            type: ArtistGroupActionType.setFavorited,
            payload: search,
        })
    }, [customerId, dispatch, favorites, group.ecId])

    const handleAddFavorite = () => {
        if (isAuth) {
            if (!isLoading && customerId && group.ecId) {
                dispatch({
                    type: ArtistGroupActionType.addFavorite,
                    payload: {
                        customerId,
                        groupId: group.ecId,
                    },
                })
            }
        } else {
            window.location.href = `${process.env.REACT_APP_API_AUTH}?callback=${window.location.origin}/portal/callback`
        }
    }

    const handleDeleteFavorite = () => {
        if (!isLoading && isAuth && isFavorited && customerId && group.ecId) {
            dispatch({
                type: ArtistGroupActionType.removeFavorite,
                payload: {
                    customerId,
                    groupId: group.ecId,
                },
            })
        }
    }

    return (
        <>
            {!isAuth || (isAuth && !isFavorited) ? (
                <button
                    type="button"
                    className="group__btn group__btn-rounded"
                    onClick={handleAddFavorite}
                >
                    <div className="btn-div-heart-icon">
                        {isLoading ? (
                            <Spin indicator={antIcon} className="mt-5" />
                        ) : (
                            <div style={{ display: 'flex' }}>
                                <img
                                    src={heart}
                                    width={18}
                                    height={18}
                                    className="img-heart"
                                    alt="heart"
                                />
                                <span>お気に入り登録する</span>
                            </div>
                        )}
                    </div>
                </button>
            ) : (
                <button
                    type="button"
                    className="group__btn group__btn-rounded group-btn-favorite"
                    onClick={handleDeleteFavorite}
                >
                    <div className="btn-div-heart-icon">
                        {isLoading ? (
                            <Spin indicator={antIcon} className="mt-5" />
                        ) : (
                            <div style={{ display: 'flex' }}>
                                <HeartFilled className="group-heart-action" />
                                お気に入り登録中
                            </div>
                        )}
                    </div>
                </button>
            )}
        </>
    )
}

export default GroupFavoriteButton

GroupFavoriteButton.defaultProps = {
    id: '',
}
