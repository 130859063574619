/* eslint-disable camelcase */
import { ProductKindType, ProductType } from '../../type/ProductType'
import { formatKeys } from '../../utils/filters'
import EcService from '../ec.service'

export interface ProductQueryParams {
    artist_id?: string
    sale_type?: string
    Sp_member_period_type?: string
    new_flg?: string
    recent_flg?: string
    on_sale_flg?: string
    sort_column?: string
    sort_way?: string
    live_type?: string
    limit?: string
    offset?: string
    group_id?: string
}

export interface ProductListUrlQueryParams {
    product_kind: ProductKindType['kind']
}

export interface GetGroupProductListUrlQueryParams {
    ecId: string
}

class ProductService extends EcService {
    /**
     * Get raw product list from Ec Cube
     *
     * @param params
     */
    public getProducts(
        params: ProductQueryParams = {}
    ): Promise<ProductType[]> {
        return new Promise<ProductType[]>((resolve, reject) => {
            this.client
                .get('/GetProduct', {
                    params,
                })
                .then((response) => {
                    const responseFormatted: ProductType[] =
                        response.data.data.map((product: any) => {
                            return formatKeys(product)
                        })

                    resolve(responseFormatted)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get redirect URL of product list on ECCUBE
     *
     * @param params
     */
    public getProductListUrl(
        params: ProductListUrlQueryParams
    ): Promise<string> {
        let shop_id: string
        switch (params.product_kind) {
            case 'live':
                shop_id =
                    process.env.REACT_APP_SHOP_ID_LIVE_TICKET_CATEGORY ?? ''
                break
            case 'artist-goods':
                shop_id =
                    process.env
                        .REACT_APP_SHOP_ID_NON_DIGITAL_PRODUCT_CATEGORY ?? ''
                break
            case 'personal-talk':
                shop_id =
                    process.env
                        .REACT_APP_SHOP_ID_PERSONAL_TALK_TICKET_CATEGORY ?? ''
                break
            case 'gacha-coin':
                shop_id = process.env.REACT_APP_SHOP_ID_GACHA_COIN ?? ''
                break
            default:
                shop_id = ''
        }

        return new Promise<string>((resolve, reject) => {
            this.client
                .get('GetProductListUrl', { params: { shop_id } })
                .then((response) => {
                    resolve(response.data.product_list_url)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    /**
     * Get URL of group product list on ECCUBE
     *
     * @param params
     */
    public getGroupProductListUrl(
        params: GetGroupProductListUrlQueryParams
    ): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.client
                .get('GetProductListUrl', {
                    params: { group_ec_id: params.ecId },
                })
                .then((response) => {
                    resolve(response.data.product_list_url)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getDetailProductId(id: number | string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.client
                .get('/GetDetailCategory/artistGroup', {
                    params: { artist_group_id: id },
                })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

export default new ProductService()
