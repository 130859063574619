import {
    GachaStateType,
    GachaActions,
    GachaActionTypes,
} from '../constant/gacha.const'

const initialState: GachaStateType = {
    isCarouselLoading: false,
    isCollectionLoading: false,
    gachasCms: [],
    gachasEc: [],
    carousel: [],
    countGachas: 0,
}

function gachaReducers(
    state = initialState,
    actions: GachaActions
): GachaStateType {
    switch (actions.type) {
        case GachaActionTypes.setCarouselLoading:
            return { ...state, isCarouselLoading: actions.payload }
        case GachaActionTypes.setCollectionLoading:
            return { ...state, isCollectionLoading: actions.payload }
        case GachaActionTypes.setGachasCms:
            return { ...state, gachasCms: actions.payload }
        case GachaActionTypes.setGachasEc:
            return { ...state, gachasEc: actions.payload }
        case GachaActionTypes.setCarousel:
            return { ...state, carousel: actions.payload }
        case GachaActionTypes.setCountGachas:
            return { ...state, countGachas: actions.payload }
        default:
            return state
    }
}

export default gachaReducers
