import { Action } from 'redux'
import { ProductType } from '../../type/ProductType'

export type ProductStateType = {
    isLiveTicketsLoading: boolean
    isArtistGoodsLoading: boolean
    isPersonalTalkTicketsLoading: boolean
    liveTickets: ProductType[]
    artistGoods: ProductType[]
    personalTalksTickets: ProductType[]
    redirectUrlToLiveTickets: string
    redirectUrlToArtistGoods: string
    redirectUrlToPersonalTalkTickets: string
    redirectUrlToGachaCoin: string
}

export interface ProductActions<T = any> extends Action {
    payload?: T
}

export const ProductActionTypes = {
    setLiveTicketsLoading: 'PRODUCT_SET_LIVE_TICKETS_LOADING',
    setArtistGoodsLoading: 'PRODUCT_SET_ARTIST_GOODS_LOADING',
    setPersonalTalksLoading: 'PRODUCT_SET_PERSONAL_TALKS_LOADING',
    fetchLiveTickets: 'PRODUCT_FETCH_LIVE_TICKETS',
    setLiveTickets: 'PRODUCT_SET_LIVE_TICKETS',
    fetchArtistGoods: 'PRODUCT_FETCH_ARTIST_GOODS',
    setArtistGoods: 'PRODUCT_SET_ARTIST_GOODS',
    fetchPersonalTalkTickets: 'PRODUCT_FETCH_PERSONAL_TALK_TICKETS',
    setPersonalTalkTickets: 'PRODUCT_SET_PERSONAL_TALK_TICKETS',
    setRedirectUrlToLiveTickets: 'PRODUCT_SET_REDIRECT_URL_TO_LIVE_TICKETS',
    setRedirectUrlToArtistGoods: 'PRODUCT_SET_REDIRECT_URL_TO_ARTIST_GOODS',
    setRedirectUrlToPersonalTalkTickets:
        'PRODUCT_SET_REDIRECT_URL_TO_PERSONAL_TALK_TICKETS',
    fetchGachaCoin: 'PRODUCT_FETCH_GACHA_COIN',
    setRedirectUrlToGachaCoin: 'PRODUCT_SET_REDIRECT_URL_TO_GACHA_COIN',
}
