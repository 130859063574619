import React, { useEffect } from 'react'
import * as _ from 'lodash'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import PortalLayout from './layouts/PortalLayout'
import ArtistGroupLayout from './layouts/ArtistGroupLayout'
import GachaLayout from './layouts/GachaLayout'
import ScrollToTop from './components/ScrollToTop'
import useQuery from './utils/hooks'
import { AuthService } from './services/auth.service'
import { useAppSelector, useAppDispatch } from './store/hooks/AppHooks'
import { ThemeActionsType } from './store/constant/theme.const'
import settingStyle from './utils/utils'

const App = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const query = useQuery()
    const frontTheme = useAppSelector((state) => state.theme.frontTheme)
    const root = document.documentElement.style
    const prefix =
        _.first(location.pathname.split('/').filter((i) => i !== '')) ??
        'portal'

    useEffect(() => {
        if (prefix === 'portal') {
            dispatch({
                type: ThemeActionsType.fetchFrontTheme,
            })
        }
    }, [prefix, dispatch])

    useEffect(() => {
        settingStyle(root, frontTheme)
    }, [root, frontTheme])

    const profileRouting = () => {
        // window.location.href = `${process.env.REACT_APP_EC_API_URL}/mypage`
        const referrer = query.get('referrer')
        if (referrer !== null) {
            if (process.env.REACT_APP_NFT_URL?.indexOf(referrer) === -1) {
                window.location.href = referrer
            } else {
                window.location.href = `${window.location.origin}/portal`
            }
        } else {
            window.location.href = `${window.location.origin}/portal`
        }
        return null
    }

    const handleCallbackWithToken = () => {
        window.location.href =
            query.get('referrer') ?? `${window.location.origin}/portal`
        return null
    }

    const handleLogoutCallback = () => {
        AuthService.removeToken()
        window.location.href = `${process.env.REACT_APP_EC_API_URL}/mypage/login`
        return null
    }

    if (query.has('token')) {
        AuthService.setToken(query.get('token'))
    }

    return (
        <div className="app">
            <ScrollToTop />
            <Switch>
                <Route path="/" exact>
                    <Redirect to="/portal" />
                </Route>
                <Route
                    path="/portal/profile"
                    exact
                    component={profileRouting}
                />
                <Route
                    path="/portal/callback"
                    exact
                    component={handleCallbackWithToken}
                />
                <Route
                    path="/portal/callback/logout"
                    exact
                    component={handleLogoutCallback}
                />
                <Route path="/portal" key="index" component={PortalLayout} />
                <Route
                    path="/groups"
                    key="artist_groups"
                    component={ArtistGroupLayout}
                />
                <Route
                    path="/gacha"
                    key="gacha-group"
                    component={GachaLayout}
                />
            </Switch>
        </div>
    )
}

export default App
