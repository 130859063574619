import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { message } from 'antd'

function Protected(): JSX.Element {
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        message.warn(
            'このページにアクセスするためにログインする必要があります。'
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (location.pathname === '/portal/favorites') {
            window.location.href = `${process.env.REACT_APP_EC_API_URL}/mypage/login`
            return
        }
        history.push('/')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    return <></>
}

export default Protected
