import { all, put, takeLatest, call, select, fork } from 'redux-saga/effects'
import {
    ArtistGroupActions,
    ArtistGroupActionType,
} from '../constant/group.const'
import { ArtistActionTypes } from '../constant/artist.const'
import GroupCmsService from '../../services/groups/groupCms.service'
import GroupEcService from '../../services/groups/groupsEc.service'
import {
    GroupCms,
    GroupEc,
    GroupNewsType,
    GroupTopSlide,
} from '../../type/GroupType'
import { rootState } from '../index'
import { ArtistType } from '../../type/ArtistType'
import { ProductType } from '../../type/ProductType'
import ProductService, {
    GetGroupProductListUrlQueryParams,
    ProductQueryParams,
} from '../../services/product/index'
import { ArtistInfoType } from '../../type/ArtistInfoType'
import ArtistCmsService from '../../services/artist/artistCms.service'
import { GroupFavoriteType } from '../../type/GroupFavoriteType'

/**
 * Fetching data from CMS
 * @param actions
 */
export function* getArtistGroupFromCms(actions: ArtistGroupActions) {
    // Set loading indicator
    yield put({
        type: ArtistGroupActionType.setLoading,
        payload: true,
    })
    // Reset the errors
    yield put({
        type: ArtistGroupActionType.resetErrors,
    })

    try {
        const data: GroupCms = yield call(
            GroupCmsService.getGroupBySlug.bind(
                GroupCmsService,
                actions.payload
            )
        )

        // Set data
        yield put({
            type: ArtistGroupActionType.setCurrentGroupData,
            payload: data,
        })
        // Set current id
        yield put({
            type: ArtistGroupActionType.setCurrentGroupCmsId,
            payload: actions.payload,
        })
        // Marking it is fetched
        yield put({
            type: ArtistGroupActionType.setFetched,
            payload: true,
        })
    } catch (e) {
        yield put({
            type: ArtistGroupActionType.setErrors,
            payload: e,
        })
        // Marking it is fetched
        yield put({
            type: ArtistGroupActionType.setFetched,
            payload: false,
        })
    }

    // Set loading indicator
    yield put({
        type: ArtistGroupActionType.setLoading,
        payload: false,
    })
}

/**
 * Fetching News from CMS
 */
export function* getArtistGroupNews() {
    // Set loading indicator
    yield put({
        type: ArtistGroupActionType.setLoading,
        payload: true,
    })

    try {
        const isGroupFetched: boolean = yield select(
            (state: rootState) => state.artistGroup.isFetched
        )
        const currentGroupDataCms: GroupCms = yield select(
            (state: rootState) => state.artistGroup.currentGroupDataCms
        )
        if (isGroupFetched && currentGroupDataCms) {
            const data: GroupNewsType = yield call(
                GroupCmsService.getGroupNews.bind(
                    GroupCmsService,
                    currentGroupDataCms.id
                )
            )
            yield put({
                type: ArtistGroupActionType.setNews,
                payload: data,
            })
        }
    } catch (error) {
        yield put({
            type: ArtistGroupActionType.setErrors,
            payload: error,
        })
    }

    // Set loading indicator
    yield put({
        type: ArtistGroupActionType.setLoading,
        payload: false,
    })
}

/**
 * Fetching artist list from EC
 */
export function* getArtistsListFromEc() {
    try {
        yield put({
            type: ArtistGroupActionType.setLoadingArtistList,
            payload: true,
        })
        const { ecId } = yield select(
            (state) => state.artistGroup.currentGroupDataCms
        )
        const currentSearchString: string = yield select(
            (state: rootState) => state.artistGroup.stringSearched
        )

        if (ecId) {
            // Call API for get list Artist from Ec
            const list: Array<ArtistType> = yield call(
                GroupEcService.getArtistList.bind(GroupEcService),
                ecId,
                currentSearchString
            )
            yield put({
                type: ArtistGroupActionType.setArtistsList,
                payload: list,
            })
            yield put({
                type: ArtistGroupActionType.fetchArtistListInfo,
            })
        }
    } catch (error) {
        yield put({
            type: ArtistGroupActionType.setErrors,
            payload: error,
        })
    } finally {
        yield put({
            type: ArtistGroupActionType.setLoadingArtistList,
            payload: false,
        })
    }
}

export function* getArtistInfoFromCms() {
    const artists: Array<ArtistType> = yield select(
        (state) => state.artistGroup.artistsList
    )

    if (artists.length > 0) {
        const ids = artists.map((artist) => {
            return artist.artistId
        })
        const artistsInfo: Array<ArtistInfoType> = yield call(
            ArtistCmsService.getArtists.bind(ArtistCmsService),
            ids
        )
        yield put({
            type: ArtistGroupActionType.setArtistsInfo,
            payload: artistsInfo,
        })
    }
}

export function* getArtistGroupsFromEc() {
    try {
        const groups: Array<GroupEc> = yield call(
            GroupEcService.getGroups.bind(GroupEcService)
        )
        yield put({
            type: ArtistGroupActionType.setGroupsFromEc,
            payload: groups,
        })
    } catch (error) {
        yield put({
            type: ArtistGroupActionType.setErrors,
            payload: error,
        })
    }
}

export function* getGroupTopSlides(actions: ArtistGroupActions) {
    try {
        const { groupId } = actions.payload
        const data: Array<GroupTopSlide> = yield call(
            GroupCmsService.getTopSlides.bind(GroupCmsService, groupId)
        )
        yield put({
            type: ArtistGroupActionType.setGroupTopSlides,
            payload: data,
        })
    } catch (error) {
        yield put({
            type: ArtistGroupActionType.setErrors,
            payload: error,
        })
    }
}

export function* getGroupTopMiddleSlides(actions: ArtistGroupActions) {
    try {
        const { groupId } = actions.payload
        const data: Array<GroupTopSlide> = yield call(
            GroupCmsService.getMiddleSlides.bind(GroupCmsService, groupId)
        )
        yield put({
            type: ArtistGroupActionType.setGroupTopMiddleSlides,
            payload: data,
        })
    } catch (error) {
        yield put({
            type: ArtistGroupActionType.setErrors,
            payload: error,
        })
    }
}

export function* getGroupEcMalls() {
    try {
        const { ecId } = yield select(
            (state) => state.artistGroup.currentGroupDataCms
        )

        if (ecId) {
            const ecMalls: Array<ProductType> = yield call(
                ProductService.getProducts.bind(ProductService),
                {
                    sale_type: '1',
                    new_flg: '1',
                    group_id: ecId,
                } as ProductQueryParams
            )
            yield put({
                type: ArtistGroupActionType.setGroupEcMalls,
                payload: ecMalls,
            })
        } else {
            yield put({
                type: ArtistGroupActionType.setGroupEcMalls,
                payload: [],
            })
        }
    } catch (error) {
        yield put({
            type: ArtistGroupActionType.setErrors,
            payload: error,
        })
    }
}

export function* getGroupProductUrl() {
    try {
        const { ecId } = yield select(
            (state) => state.artistGroup.currentGroupDataCms
        )

        if (ecId) {
            const ecUrl: string = yield call(
                ProductService.getGroupProductListUrl.bind(ProductService),
                {
                    ecId,
                } as GetGroupProductListUrlQueryParams
            )
            yield put({
                type: ArtistGroupActionType.setGroupProductsUrl,
                payload: ecUrl,
            })
        } else {
            yield put({
                type: ArtistGroupActionType.setGroupProductsUrl,
                payload: '',
            })
        }
    } catch (error) {
        yield put({
            type: ArtistGroupActionType.setErrors,
            payload: error,
        })
    }
}

export function* getGroupLiveTickets() {
    try {
        const { ecId } = yield select(
            (state) => state.artistGroup.currentGroupDataCms
        )

        if (ecId) {
            const liveTickets: Array<ProductType> = yield call(
                ProductService.getProducts.bind(ProductService),
                {
                    sale_type: '6',
                    recent_flg: '1',
                    on_sale_flg: '1',
                    sort_column: 'venue_start_date',
                    sort_way: '0',
                    group_id: ecId,
                } as ProductQueryParams
            )
            yield put({
                type: ArtistGroupActionType.setGroupLiveTickets,
                payload: liveTickets,
            })
        } else {
            yield put({
                type: ArtistGroupActionType.setGroupLiveTickets,
                payload: [],
            })
        }
    } catch (error) {
        yield put({
            type: ArtistGroupActionType.setErrors,
            payload: error,
        })
    }
}

export function* getFavorites(actions: ArtistGroupActions) {
    try {
        yield put({
            type: ArtistGroupActionType.setFavoriteLoading,
            payload: true,
        })
        const favorites: GroupFavoriteType[] = yield call(
            GroupEcService.getFavorites.bind(GroupEcService),
            actions.payload.customerId,
            actions.payload.groupId
        )

        yield put({
            type: ArtistGroupActionType.setFavorites,
            payload: favorites,
        })
    } catch (error) {
        yield put({
            type: ArtistGroupActionType.setErrors,
            payload: error,
        })
    } finally {
        yield put({
            type: ArtistGroupActionType.setFavoriteLoading,
            payload: false,
        })
    }
}

export function* addFavorite(actions: ArtistGroupActions) {
    try {
        yield put({
            type: ArtistGroupActionType.setFavoriteLoading,
            payload: true,
        })

        yield call(
            GroupEcService.addFavorite.bind(GroupEcService),
            actions.payload.customerId,
            actions.payload.groupId
        )

        yield put({
            type: ArtistGroupActionType.setFavorited,
            payload: true,
        })
    } catch (error) {
        yield put({
            type: ArtistGroupActionType.setErrors,
            payload: error,
        })
    } finally {
        yield put({
            type: ArtistGroupActionType.setFavoriteLoading,
            payload: false,
        })
    }
}

export function* removeFavorite(actions: ArtistGroupActions) {
    try {
        yield put({
            type: ArtistActionTypes.setFetchingFavorites,
            payload: true,
        })

        // get customer id from Redux store
        const customerId: number = yield select(
            (state: rootState) => state.user.me.ecCubeId
        )

        yield call(
            GroupEcService.removeFavorite.bind(GroupEcService),
            customerId.toString(),
            actions.payload.groupId
        )

        yield put({
            type: ArtistGroupActionType.fetchFavorites,
            payload: {
                getAll: true,
                customerId,
            },
        })
        yield put({
            type: ArtistActionTypes.setFetchingFavorites,
            payload: false,
        })
    } catch (error) {
        yield put({
            type: ArtistGroupActionType.setErrors,
            payload: error,
        })
    } finally {
        yield put({
            type: ArtistActionTypes.setFetchingFavorites,
            payload: false,
        })
    }
}

export function* artistGroupSaga() {
    yield all([
        takeLatest(ArtistGroupActionType.fetchFromCms, getArtistGroupFromCms),
        takeLatest(ArtistGroupActionType.fetchNews, getArtistGroupNews),
        takeLatest(
            ArtistGroupActionType.fetchArtistsList,
            getArtistsListFromEc
        ),
        takeLatest(
            ArtistGroupActionType.fetchArtistListInfo,
            getArtistInfoFromCms
        ),
        fork(getArtistGroupsFromEc),
        takeLatest(
            ArtistGroupActionType.fetchGroupTopSlides,
            getGroupTopSlides
        ),
        takeLatest(
            ArtistGroupActionType.fetchGroupTopMiddleSlides,
            getGroupTopMiddleSlides
        ),
        // takeLatest(ArtistGroupActionType.fetchGroupEcMalls, getGroupEcMalls),
        takeLatest(
            ArtistGroupActionType.fetchGroupLiveTickets,
            getGroupLiveTickets
        ),
        takeLatest(ArtistGroupActionType.fetchFavorites, getFavorites),
        takeLatest(ArtistGroupActionType.addFavorite, addFavorite),
        takeLatest(ArtistGroupActionType.removeFavorite, removeFavorite),
        takeLatest(
            ArtistGroupActionType.fetchGroupProductUrl,
            getGroupProductUrl
        ),
    ])
}
