import { AxiosError } from 'axios'
import * as _ from 'lodash'
import { stringify } from 'qs'
import CmsService from '../cms.service'
import { formatKeys } from '../../utils/filters'
import { GroupCms, GroupNewsType, GroupTopSlide } from '../../type/GroupType'

class GroupCmsService extends CmsService {
    public getGroupInfo(id: number) {
        return new Promise<GroupCms>((resolve, reject) => {
            this.client
                .get(`artist-groups/${id}`)
                .then((response) => {
                    const groupData = formatKeys(response.data)
                    resolve(groupData as GroupCms)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getGroupBySlug(slug: string) {
        return new Promise<GroupCms>((resolve, reject) => {
            const query = stringify({
                _where: [{ slug }],
            })
            this.client
                .get(`artist-groups?${query}`)
                .then((response) => {
                    if (response.data.length > 0) {
                        const groupData = formatKeys(response.data[0])
                        resolve(groupData as GroupCms)
                    } else {
                        reject(new Error('Not found'))
                    }
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getGroupNews(id: number) {
        return new Promise<Array<GroupNewsType>>((resolve, reject) => {
            this.client
                .get('artist-group-news', {
                    params: {
                        _limit: 3,
                        'artist_group.id': id,
                        _sort: 'id:desc',
                    },
                })
                .then((response) => {
                    const groupNews = _.map(response.data, (item) => {
                        return formatKeys(item)
                    })
                    resolve(groupNews as Array<GroupNewsType>)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getListGroupNews(id: number, limit: number, start: number) {
        return new Promise<Array<GroupNewsType>>((resolve, reject) => {
            this.client
                .get('artist-group-news', {
                    params: {
                        _limit: limit,
                        'artist_group.id': id,
                        _start: start,
                        _sort: 'id:desc',
                    },
                })
                .then((response) => {
                    const groupNews = _.map(response.data, (item) => {
                        return formatKeys(item)
                    })
                    resolve(groupNews as Array<GroupNewsType>)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getAllGroupNews(id: number) {
        return new Promise<Array<GroupNewsType>>((resolve, reject) => {
            this.client
                .get('artist-group-news', {
                    params: {
                        'artist_group.id': id,
                    },
                })
                .then((response) => {
                    const groupNews = _.map(response.data, (item) => {
                        return formatKeys(item)
                    })
                    resolve(groupNews as Array<GroupNewsType>)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getGroupNewsArticle(newsId: string) {
        return new Promise<GroupNewsType>((resolve, reject) => {
            this.client
                .get(`artist-group-news/${newsId}`)
                .then((response) => {
                    const groupNews: any = formatKeys(response.data)
                    resolve(groupNews as GroupNewsType)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getTopSlides(id: number) {
        return new Promise<Array<GroupTopSlide>>((resolve, reject) => {
            const query = stringify({
                _where: [{ artist_group: id }],
            })
            this.client
                .get(`group-top-carousels?${query}`)
                .then((response) => {
                    const formatted = _.map(response.data, (item) => {
                        return formatKeys(item)
                    })
                    resolve(formatted as Array<GroupTopSlide>)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getMiddleSlides(id: number) {
        return new Promise<Array<GroupTopSlide>>((resolve, reject) => {
            // Query string for filter slides by group Id
            const query = stringify({
                _where: [{ artist_group: id }],
            })
            this.client
                .get(`/group-middle-slides?${query}`)
                .then((response) => {
                    const formatted = _.map(response.data, (item) => {
                        return formatKeys(item)
                    })
                    resolve(formatted as Array<GroupTopSlide>)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }

    public getGroupByIds(ids: Array<string>) {
        const query = stringify({
            id_in: ids.map((id) => {
                return id
            }),
        })

        return new Promise<Array<GroupCms>>((resolve, reject) => {
            this.client
                .get(`artist-groups?${query}`)
                .then((response) => {
                    const formatted = _.map(response.data, (group) => {
                        return formatKeys(group)
                    })
                    resolve(formatted as Array<GroupCms>)
                })
                .catch((error: AxiosError) => {
                    reject(error)
                })
        })
    }
}

export default new GroupCmsService()
