import {
    BlogStateType,
    BlogActions,
    BlogActionsType,
} from '../constant/blog.const'

const initialState: BlogStateType = {
    isLoading: false,
    list: [],
    count: 0,
}

function blogReducers(
    state = initialState,
    actions: BlogActions
): BlogStateType {
    switch (actions.type) {
        case BlogActionsType.setLoading:
            return { ...state, isLoading: actions.payload }
        case BlogActionsType.setList:
            return { ...state, list: actions.payload }
        case BlogActionsType.setCount:
            return { ...state, count: actions.payload }
        default:
            return state
    }
}

export default blogReducers
