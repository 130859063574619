import React, {
    ChangeEventHandler,
    FormEventHandler,
    MouseEventHandler,
    useState,
} from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { useAppDispatch } from '../../store/hooks/AppHooks'
import { ArtistActionTypes } from '../../store/constant/artist.const'

const HeaderNavigation = (): JSX.Element => {
    const [searchedValue, setSearchedValue] = useState('')
    const dispatch = useAppDispatch()

    const handleSearchedValueChange: ChangeEventHandler<HTMLInputElement> = (
        e
    ) => {
        setSearchedValue(e.target.value)
    }
    const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()
        dispatch({
            type: ArtistActionTypes.setStringSearched,
            payload: searchedValue,
        })
        dispatch({
            type: ArtistActionTypes.fetchList,
            payload: { isListInfoNeeded: true },
        })
    }
    const handleClickSubmit: MouseEventHandler<HTMLButtonElement> = (e) => {
        dispatch({
            type: ArtistActionTypes.setStringSearched,
            payload: searchedValue,
        })
        dispatch({
            type: ArtistActionTypes.fetchList,
            payload: { isListInfoNeeded: true },
        })
    }
    return (
        <div className="portal__header__navigation-wrapper">
            <div className="portal__header__navigation-search">
                <form onSubmit={handleSubmit}>
                    <Input
                        size="large"
                        suffix={
                            <button type="button" onClick={handleClickSubmit}>
                                <SearchOutlined
                                    style={{ verticalAlign: 'middle' }}
                                />
                            </button>
                        }
                        className="portal__input-search"
                        onChange={handleSearchedValueChange}
                    />
                </form>
            </div>
        </div>
    )
}
export default HeaderNavigation
